
import { Component, Mixins } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { format, parse } from "fecha";
import { HasFormErrors } from "@/mixins/has-form-errors";
import { formatDate } from "@/utils/formatters";
import { bus } from "@/main";
import type { CalendarAction } from "@/store/modules/appointment.store";

@Component({
    components: {
        Spinner: () => import("@/components/general/spinner.vue"),
        AppointmentCard: () => import("@/components/appointments/card.vue"),
        AppointmentCreateModal: () => import("@/components/modals/create-appointment.vue"),
        AppointmentDeleteModal: () => import("@/components/modals/delete-appointment.vue"),
        AppointmentUpdateModal: () => import("@/components/modals/update-appointment.vue"),
    },
})
export default class DossierTabAppointments extends Mixins(HasFormErrors) {
    @Getter("activity/viewing") activity!: Activity;
    @Getter("appointment/all") appointments!: Appointment[];
    @Getter("appointment/viewing") viewingAppointment!: Appointment;
    @Getter("appointment/actions") actions!: CalendarAction[];
    @Action("appointment/indexV1") indexAppointments!: AppointmentIndex;
    @Action("appointment/delete") deleteAppointment!: AppointmentDelete;
    @Action("appointment/setViewing") setViewing!: AppointmentSetViewing;
    @Action("appointment/clearViewing") clearViewing!: () => void;
    @Action("appointment/actions") getActions!: () => Promise<CalendarAction[]>;

    loading = false;

    formatDate = formatDate;

    bus = bus;

    tab = "future";

    action: string | null = null;

    get futureAppointments() {
        return this.appointments
            .filter((a: Appointment) => parse(a.planned_at, "isoDateTime")! >= new Date())
            .sort((a: Appointment, b: Appointment) => {
                if (a.planned_at <= b.planned_at) {
                    return -1;
                } else {
                    return 1;
                }
            });
    }

    get pastAppointments() {
        return this.appointments
            .filter((a: Appointment) => parse(a.planned_at, "isoDateTime")! < new Date())
            .sort((a: Appointment, b: Appointment) => {
                if (a.planned_at >= b.planned_at) {
                    return -1;
                } else {
                    return 1;
                }
            });
    }

    get filteredAppointments() {
        if (this.tab === "future") {
            return this.futureAppointments;
        }
        return this.pastAppointments;
    }

    get isMobile() {
        // @ts-ignore
        return this.$root.isMobile;
    }

    async mounted() {
        this.loading = true;

        await this.indexAppointments({ activity_id: this.activity.id });

        this.loading = false;
    }

    prettifyTime(dateString: string, duration: number) {
        const date = parse(dateString, "isoDateTime");
        const endDate = new Date(date!.getTime() + duration * 60000);
        return `${format(date as Date, "HH:mm")} - ${format(endDate as Date, "HH:mm")}`;
    }
}
